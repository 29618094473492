import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import Layout from "../../../../../components/layout"
import Seo from "../../../../../components/seo"
import logo from "../../../../../images/logo.png"
import Moment from "react-moment"
import "moment/locale/es"
import * as Services from "../../../../../services"

import { Bar } from "react-chartjs-2"
import { Chart as ChartJS, registerables } from "chart.js"

import html2canvas from "html2canvas"
import { jsPDF } from "jspdf"

ChartJS.register(...registerables)

function PrintPage() {
  const [day, setDay] = useState("")
  const [farmDet, setFarmDetail] = useState(null)
  const [calculos, setCalculos] = useState([])
  const [evaluaciones, setEvaluaciones] = useState([])
  const [chartData, setChartData] = useData(calculos)

  const printRef = React.useRef()

  const handleDownloadPdf = async () => {
    /*const element = printRef.current;
    const canvas = await html2canvas(element);
    const data = canvas.toDataURL('image/png');

    const pdf = new jsPDF();
    const imgProperties = pdf.getImageProperties(data);
 
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight =
      (imgProperties.height * pdfWidth) / imgProperties.width;

    pdf.addImage(data, 'PNG', 0, 0, pdfWidth, pdfHeight);
    pdf.save('reporte.pdf');*/
    window.scrollTo(0, 0)
    const element = printRef.current
    html2canvas(element, {
      scrollX: -window.scrollX,
      scrollY: -window.scrollY,
      windowWidth: document.documentElement.offsetWidth,
      windowHeight: element.scrollHeight,
    }).then(canvas => {
      const img = new Image()

      const imgData = canvas
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream")

      const pdf = new jsPDF("p", "mm", "a4")
      const imgProps = pdf.getImageProperties(imgData)
      const pdfWidth = pdf.internal.pageSize.getWidth()
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width
      pdf.addImage(imgData, "JPEG", 0, 0, pdfWidth, pdfHeight)
      pdf.save("reporteProd.pdf")
    })
  }

  useEffect(() => {
    const dia = localStorage.getItem("dia")
    const farm = localStorage.getItem("productor")
      ? JSON.parse(localStorage.getItem("productor"))
      : null
    setFarmDetail(farm)
    setDay(dia)
    Services.ReportsDetail(farm?.id, dia)
      .then(response => {
        console.log(farm)
        setEvaluaciones(response.reportes.evaluaciones)
        setCalculos(response.calculos)
      })
      .catch(error => {
        console.log(error)
      })
  }, [])

  const date = new Date()

  return (
    <Layout>
      <button
        className="inline-flex items-center justify-center p-3 w-full bg-green-light-gu rounded-full font-bold text-white focus:ring-2 focus:ring-green-light-gu focus:bg-green-gu"
        type="button"
        onClick={handleDownloadPdf}
      >
        Descargar Reporte
      </button>

      <div className="container2" ref={printRef}>
        <table>
          <tr>
            <td width="50%" align="left">
              <img src={logo} alt="GREEN UP" />
            </td>
            <td></td>
            <td align="right" width="50%">
              <Moment locale="es" format="dddd, DD [de] MMMM [de] YYYY">
                {date}
              </Moment>
              <h2>
                <b>Reporte Productor</b>
              </h2>
            </td>
          </tr>
        </table>
        <table>
          <tr>
            <td width="50%" align="left">
              <h2>
                <b>Productor:</b> {farmDet?.name}
              </h2>
            </td>
            <td align="right"></td>
          </tr>
          <tr>
            <td width="50%" align="left">
              <h2>
                <b>Razón Social:</b> {farmDet?.razonSocial}
              </h2>
            </td>
            <td width="50%" align="left"></td>
          </tr>
          <tr>
            <td width="50%" align="left">
              <h2>
                <b>RFC:</b> {farmDet?.rfc.toUpperCase()}
              </h2>
            </td>
            <td width="50%" align="left"></td>
          </tr>
          <tr>
            <td width="50%" align="left">
              <h2>
                <b>Cultivo:</b> Fresa{" "}
              </h2>
            </td>
            <td width="50%" align="left">
              <h2>
                <b>Variedad:</b> Grande{" "}
              </h2>
            </td>
          </tr>
          <tr>
            <td width="50%" align="left">
              <h2>
                <b>Producto:</b> PEPTON 85/16{" "}
              </h2>
            </td>
            <td width="50%" align="left">
              <h2>
                <b>Dosis por Hectárea:</b> 150.5 lt{" "}
              </h2>
            </td>
          </tr>
          <tr>
            <td width="50%" align="left">
              <h2>
                <b> Método de aplicación:</b> Drench{" "}
              </h2>
            </td>
            <td width="50%" align="left">
              <h2>
                <b>Frecuencia de la aplicación:</b> 2 veces por semana{" "}
              </h2>
            </td>
          </tr>
          <tr>
            <td width="50%" align="left">
              <h2>
                <b> Duración de la evaluación:</b> 4 semanas{" "}
              </h2>
            </td>
            <td width="50%" align="left">
              <h2>
                <b>Objetivo:</b> Texto del Objetivo{" "}
              </h2>
            </td>
          </tr>
        </table>
        <br></br>
        <center>
          <b>
            <h2>DIA: {day}</h2>
          </b>
        </center>
        <br></br>
        {evaluaciones?.map((testigo, index) => {
          return (
            <div class="sidebyside">
              <div className="rectangulo">
                <h2>
                  <b> {testigo.tipo.toUpperCase()} </b>
                </h2>
              </div>
              <table className="custom">
                <thead>
                  <tr>
                    <th></th>
                    <th>No. Hojas</th>
                    <th>No. Coronas</th>
                    <th>No. Frutos</th>
                  </tr>
                </thead>
                <tbody>
                  {testigo.datosRegistrados?.map(function (dato, id) {
                    return (
                      <tr key={id}>
                        <td>
                          <b>T{parseInt(id) + 1}</b>
                        </td>
                        <td>
                          <p className="text-center">{dato.hojas}</p>
                        </td>
                        <td>
                          <p className="text-center">{dato.coronas}</p>
                        </td>
                        <td>
                          <p className="text-center">{dato.frutos}</p>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          )
        })}
        <br></br>
        <div className="centerGra">
          <Bar
            data={chartData}
            options={{
              responsive: true,
              title: { text: "THICCNESS SCALE", display: true },
              scales: {
                yAxes: {
                  ticks: {
                    beginAtZero: true,
                  },
                },
              },
            }}
          />
        </div>
        <br></br> <br></br> <br></br>
        <footer className="footer">
          <p>
            <b>
              Amado Nervo 489, col , Tangancícuaro, Mich.<br></br>Tels:
              351456789 351456789 www.green-up.mx
            </b>
          </p>
        </footer>
      </div>

      <style>
        {`.svgC {
                position:absolute;
                text-align:center;
            }
            .svgText {
                font-size:20px;
                text-align:center;
                font-weight: bold;
            }
            .container2 {
                padding: 20px 30px 0px 30px;
                margin: auto;
                font-size:14px !important;
            }
            .centerGra {
                margin: auto;
                width: 80%;
                padding: 5px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .rectangulo {
                height:30px;
                background: #73AD21;
                text-align:center;
                border: .5px solid;
            }
            .footer {
              margin: auto;
              width: 100%;
              height: 70px;
              padding: 10px;
              display: flex;
              align-items: center;
              justify-content: center;
              background: #73AD21;
              text-align:center;
              border-top-left-radius: 50%;
              border-top-right-radius: 50%;
          }
            .sidebyside {
                display: inline-block;
                width: 50%;
                margin: auto;          
                padding: 5px 40px 15px 40px; 
                align-items: center;
                justify-content: center;
              
            }
            .custom {
              width:100%;
              table-layout: fixed;
              border-collapse: collapse;
              border: .5px solid;
            }
        `}
      </style>
    </Layout>
  )
}
export const Head = () => <Seo title="Reporte PDF" />

export default PrintPage

const useData = (data = {}) => {
  const [state, setState] = useState(data)

  let valores = []
  let valores1 = []
  let labels = []

  Object.keys(data).map((e, i) => {
    labels.push(e)
    valores.push(data[e][0])
    valores1.push(data[e][1])
  })

  data = {
    labels: labels ? labels : "[1,2,3]",
    datasets: [
      {
        label: "Testigo",
        data: valores ? valores : "[0]",
        backgroundColor: "#6D993E",
        borderColor: "#6D993E",
        borderWidth: 1,
      },
      {
        label: "Tratamiento",
        data: valores1 ? valores1 : "[0]",
        backgroundColor: "#8bfd78",
        borderColor: "#8bfd78",
        borderWidth: 1,
      },
    ],
  }
  return [data]
}
